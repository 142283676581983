import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import LogicBaseAppReportCache from '../../services/cache/LogicBaseAppReportCache';
import UserCache from '../../services/cache/UserCache';
import TreeCache from '../../services/cache/TreeCache';
import LogicBaseCommentsCache from '../../services/cache/LogicBaseCommentsCache';
import LogicBaseCommentLikeCache from '../../services/cache/LogicBaseCommentLikeCache';
import LogicBaseAppLikeCache from '../../services/cache/LogicBaseAppLikeCache';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import UserProfileHeader from '../../components/UserProfileHeader/index';
import LogicBaseAppSettings from '../../components/LogicBaseAppSettings/index';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import TreeAnswerPage from '../TreeAnswerPage';
import LogicBaseAppList from '../../components/LogicBaseAppList';
import { getAppComments, getLogicBaseAppsSearch, getLogicBaseTopLikesApps } from '../../services/Pagination';
import SocialSharePopup from '../../components/SocialShare/SocialSharePopup';
import LogicBaseAppSaveCache from '../../services/cache/LogicBaseAppSaveCache';
import Scrollable from '../../components/Scrollable';
import ConfirmDialog from '../../components/ConfirmDialog';
import timeAgo from '../../utils/timeAgo';
import formatReplyCount from '../../utils/formatReplyCount';
import shortenLikesCount from '../../utils/shortenLikesCount';
import getFormattedEditString from '../../utils/getFormattedEditString';
import getFormattedTextElements from '../../utils/getFormattedTextElements';
import UserAvatar from '../../components/UserAvatar';

const Wrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  overflow: hidden;
  background: white;
  @media (max-width: 1328px) {
    background: white;
    display: block;
    inset: 0;
  }
  ${Constants.MediaMobile} {
    padding-right: 0;
    padding-left: 0;
    display: block;
    position: relative;
    margin: 0;
  }
`;
const ContentWrapper = styled.div`
  > div:first-child {
    position: absolute;
    overflow: hidden;
    inset: 0 368px 0 1rem;
    background: white;
    padding-left: 1rem;
    @media (max-width: 1328px) {
      background: unset;
      position: relative;
      overflow: hidden;
      inset: unset;
      padding-right: 2rem;
      padding-left: 1rem;
    }
    @media (max-width: 959px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    ${Constants.MediaMobile} {
      background: unset;
      position: relative;
      overflow: hidden;
      inset: unset;
      padding-left: unset;
      padding-right: unset;
    }
  }
`;
// const App = styled.div`
//   padding: 4rem 0;
//   > div {
//     display: flex;
//     align-items: center;
//     h1 {
//       margin-right: 0.5rem;
//     }
//   }
// `;
const CommentsSection = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  min-height: 50vh;
  position: relative;
  > div:last-child {
    height: 8rem;
  }
  @media (max-width: 1328px) {
    min-height: unset;
  }
  ${Constants.MediaMobile} {
    padding: 0 1rem 0;
  }
`;
const Comments = styled.div`
  background: white;
  width: 100%;
`;
const AddEditComment = styled.div`
  position: relative;
  margin: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  > i {
    padding: 0.5rem;
    cursor: pointer;
    background: white;
  }
`;
const ShareIcons = styled.div`
  cursor: pointer;
  text-align: center;
  border-radius: 1.5rem;
  font-size: 0.75rem;
  flex-shrink: 0;
  padding: 0.5rem 1rem;
  background: rgba(242, 242, 242, 1);
  height: 40px;
  display: flex;
  align-items: center;
  just-content: center;
`;
const UserProfile = styled.div`
  background-color: white;
`;
const AppWrapper = styled.div`
  background: white;
  border-radius: 1rem;
  border: 1px solid #dbdbdb;
  width: 100%;
  overflow: hidden;
  position: relative;
  // ${Constants.MediaMobile} {
  //   position: fixed;
  //   inset: 3.5rem 0 0 0;
  //   height: unset;
  //   border-radius: unset;
  //   border: unset;
  // }
`;
const AppListContent = styled.div`
  padding: 0 2rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 1328px) {
    padding: 1rem 2rem 2rem 1rem;
    position: relative;
  }
  @media (max-width: 959px) {
    padding: 1rem 0 2rem 0;
  }
  ${Constants.MediaMobile} {
    padding: 1rem 1rem 2rem 1rem;
  }
`;
const AppsWrapper = styled.div`
  background: white;
  position: absolute;
  inset: 0 0 0 calc(100% - 360px);
  overflow: hidden;
  position: absolute;
  @media (max-width: 1328px) {
    position: relative;
    overflow: hidden;
    inset: unset;
    margin-top: -7rem;
  }
  @media (max-width: 959px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  ${Constants.MediaMobile} {
    position: relative;
    overflow: hidden;
    inset: unset;
    padding-left: unset;
    padding-right: unset;
  }
`;
const ProfileAndShare = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background: white;
  gap: 2rem;
  @media (max-width: 680px) {
    display: block;
    > div:last-child {
      padding-top: 1rem;
    }
  }
  padding: 0.5rem 0;
  ${Constants.MediaMobile} {
    padding: 0.5rem 1rem;
    // border-top: 1px solid #dbdbdb;
  }
  > div {
    padding: 0.5rem 0;
  }
  > div:last-child {
    flex: 1;
  }
  > div:first-child {
    > div {
      display: flex;
      gap: 1rem;
      overflow-x: auto;
      padding-bottom: 20px;
      @media (max-width: 680px) {
        gap: 0.5rem;
      }
    }
    overflow: hidden;
    height: 56px;
  }
`;
const CommentButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 0.5rem;
  color: #333;
  white-space: nowrap;
`;
const ShowMoreButton = styled.div`
  margin: auto;
  cursor: pointer;
  text-align: center;
  border-radius: 1.5rem;
  font-size: 0.75rem;
  flex-shrink: 0;
  background: rgba(242, 242, 242, 1);
  height: 40px;
  line-height: 40px;
  width: 160px;
`;

const initialInnerHeight = window.innerHeight;

const ADD_A_COMMENT = `<span style="color: gray; pointer-events: none;">Add a comment</span>`;

export default class LogicBaseAppPage extends React.PureComponent {
  state = {
    fullscreen: Navigation.currentSearch.fullscreen === 'true',
    showShareLinks: false,
    showReplies: {},
    showReply: {},
    editReply: {},
    currentReply: '',
    previousComments: {},
    previousCommentUsers: {},
    deleteComment: null,
    commentCaches: {},
    appname: Navigation.currentLocation.slice(1).join('/')
  }
  componentDidMount() {
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    LogicBaseAppSaveCache.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseCommentsCache.onStateUpdate(this);
    LogicBaseCommentLikeCache.onStateUpdate(this);
    LogicBaseAppLikeCache.onStateUpdate(this);
    LogicBaseAppReportCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
    window.addEventListener('resize', this.windowResize);
    this.topLikesCache = getLogicBaseTopLikesApps();
    this.topLikesCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    if (this.appsCache) this.appsCache.offStateUpdate(this);
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    LogicBaseAppSaveCache.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseCommentsCache.offStateUpdate(this);
    LogicBaseCommentLikeCache.offStateUpdate(this);
    LogicBaseAppLikeCache.offStateUpdate(this);
    LogicBaseAppReportCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    document.body.style.overflow = 'unset';
    if (this.usernameTimeout) clearTimeout(this.usernameTimeout);
    Object.values(this.state.commentCaches).forEach((c) => {
      c.offStateUpdate(this)
    });
    window.removeEventListener('resize', this.windowResize);
    this.topLikesCache.offStateUpdate(this);
  }
  windowResize = () => {
    this.forceUpdate();
  }
  addCommentToPreviousComments = (comment, app, newComment, newUser) => {
    const id = comment && comment._id || app._id;
    this.state.previousComments[id] = [newComment, ...(this.state.previousComments[id] || [])];
    this.state.previousCommentUsers[id] = [newUser, ...(this.state.previousCommentUsers[id] || [])];
  }
  addEditComment = (account, app, parentComment, existingComment) => {
    const { isLoggedIn } = UserAccount;
    if (parentComment && !this.state.showReply[parentComment._id]) return null;
    const id = parentComment && `comment_${parentComment._id}_reply` || existingComment && `comment_${existingComment._id}` || 'comment';
    const shareId = `${id}_share`;
    const dropdownId = `${id}_dropdown`;
    const input = document.getElementById(id);
    let showDropdown = false;
    let usernameFound = '';
    if (input) {
      const selection = window.getSelection();
      if (selection) {
        try {
          const range = selection.getRangeAt(0);
          const textContent = range.startContainer.textContent;
          const cursorPosition = range.startOffset;
          const atIndex = textContent.lastIndexOf('@', cursorPosition);
          if (atIndex >= 0) {
            usernameFound = textContent.substring(atIndex + 1, cursorPosition);
            const spaceRegex = / /g;
            const matches = usernameFound.match(spaceRegex);
            const spacesCount = matches ? matches.length : 0;
            showDropdown = !this.hideDropdown && document.activeElement === input && usernameFound && spacesCount < 3;
          }
        } catch(e) {
          //
        }
      }
    }

    const search = this.usernameTimeout ? this.lastSearch : usernameFound;
    let users = search && UserCache.getUsers({
      page: 1,
      search,
    });

    if (showDropdown) {
      this.lastSearch = search;
    } else {
      this.lastSearch = null;
    }

    const showUser = isLoggedIn && !existingComment;
    const submitComment = () => {
      let value = '';
      document.getElementById(id).childNodes.forEach((s) => {
        if (s && s.tagName && s.tagName.toLowerCase() === 'span' && s.id) {
          value += `@${s.id.split('_')[0]}`;
        } else {
          value += s.textContent;
        }
      });
      if (!value) return;
      if (existingComment) {
        existingComment.text = value;
        this.state.editReply[existingComment._id] = false;
        LogicBaseCommentsCache.update(existingComment);
        this.forceUpdate();
      } else {
        this.addCommentToPreviousComments(parentComment, app, {
          _id: `temp_${(new Date()).getTime()}`,
          createdTime: new Date(), owner: account._id, app: (app)._id, comment: parentComment && parentComment._id || null, text: value
        }, {
          ...UserAccount.account
        });
        // Create Comment
        LogicBaseCommentsCache.create(LogicBaseCommentsCache.createObject({
          o: account._id, a: (app)._id, c: parentComment && parentComment._id || null, t: value
        }));
        if (parentComment) {
          this.state.showReply[(parentComment)._id] = false;
          this.state.showReplies[parentComment._id] = true;
        }
        this.forceUpdate();
      }
      document.getElementById(id).innerHTML = ADD_A_COMMENT;
      document.getElementById(id).blur();
    }
    return (
      <AddEditComment style={{ margin: existingComment ? 0 : '1rem 0 1.5rem' }}>
        {showUser && <UserProfileHeader
          user={{
            _id: account._id,
            image: account.image,
            fullname: account.fullname,
          }}
          small
          hideFollow
          hideProfile
        />}
        <div
          id={id}
          key={id}
          ref={(e) => {
            if (e && this.state.currentReply === id) {
              this.state.currentReply = '';
              e.focus();
            }
          }}
          tabIndex={0}
          style={{ flex: 1, height: 33, borderBottom: '1px solid rgb(193, 193, 193)', padding: '0.25rem 0rem 0.25rem 0rem', whiteSpace: 'nowrap', overflowX: 'auto' }}
          contentEditable
          role="textbox"
          dangerouslySetInnerHTML={{ __html: existingComment && existingComment.text && getFormattedEditString(existingComment.text) || ADD_A_COMMENT }}
          onPaste={(e) => {
            e.preventDefault();
            const clipboardData = e.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('text/plain').replace(/\r?\n/g, '');
            const maxLength = 250 - input.textContent.length;
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const caretPosition = range.startOffset;
            const truncatedText = pastedText.substring(0, maxLength);
            document.execCommand('insertText', false, truncatedText);
            const caretPositionAfter = Math.min(caretPosition, input.innerText.length);
            const caretPositionOffset = caretPositionAfter * 10;
            if (caretPositionOffset > input.clientWidth) input.scrollLeft = input.scrollWidth;
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              submitComment();
            } else if (e.key === 'Backspace' || e.key === 'Delete') {
              const selection = window.getSelection();
              try {
                const range = selection.getRangeAt(0);
                const { startContainer, endContainer } = range;
                Array.from(new Set([startContainer, endContainer])).forEach((element) => {
                  const spanElement = element.tagName && element || element.parentElement;
                  if (spanElement && spanElement.tagName && spanElement.tagName.toLowerCase() === 'span' && spanElement.id) {
                    if (!(range.startOffset === 0 && range.endOffset === 0)) spanElement.remove();
                  }
                })
              } catch(e) {
                //
              }
             } else if (/^[ -~]$/.test(e.key)) {
              if (input && input.textContent.length >= 250) {
                e.preventDefault();
                return;
              }
              const selection = window.getSelection();
              try {
                const range = selection.getRangeAt(0);
                const spanElement = range.commonAncestorContainer.tagName && range.commonAncestorContainer || range.commonAncestorContainer.parentElement;
                if (spanElement && spanElement.tagName && spanElement.tagName.toLowerCase() === 'span') {
                  e.preventDefault();
                  const followingSpanElement = e.key === ' ' ? document.createElement('span') : document.createTextNode('');
                  if (range.startOffset === 0 && range.endOffset === 0) {
                    if (spanElement) spanElement.parentNode.insertBefore(followingSpanElement, spanElement);
                  } else {
                    if (spanElement.nextSibling) spanElement.parentNode.insertBefore(followingSpanElement, spanElement.nextSibling);
                    else spanElement.parentNode.appendChild(followingSpanElement);
                  }
                  if (e.key === ' ') followingSpanElement.innerHTML = '&nbsp;';
                  else followingSpanElement.textContent = e.key;
                  range.selectNodeContents(followingSpanElement);
                  range.collapse(false);
                  const selection = window.getSelection();
                  selection.removeAllRanges();
                  selection.addRange(range);
                }
              } catch(e) {
                //
              }
            }
            this.hideDropdown = false;
            if (!this.usernameTimeout) {
              this.usernameTimeout = setTimeout(() => {
                this.usernameTimeout = null;
                this.forceUpdate();
              }, 500);
            }
            this.forceUpdate();
          }}
          onKeyUp={() => {
            const input = document.getElementById(id);
            Array.from(input.children).forEach((c) => {
              if (c.tagName && c.tagName.toLowerCase() === 'span' && !c.id) {
                c.style = '';
              }
            });
          }}
          onFocus={() => {
            const input = document.getElementById(id);
            if (isLoggedIn) {
              if (input) {
                if (input.innerHTML === ADD_A_COMMENT) input.innerHTML = existingComment && existingComment.text && getFormattedEditString(existingComment.text) || '';
              }
            } else Navigation.push(Navigation.login);
          }}
          onBlur={() => {
            const input = document.getElementById(id);
            if (input && !input.innerHTML) input.innerHTML = ADD_A_COMMENT;
            this.forceUpdate();
          }}
        />
        <i
          id={shareId}
          style={{ display: (input && input.innerHTML && input.innerHTML !== ADD_A_COMMENT) ? 'unset' : 'none' }}
          className="fas fa-share"
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            submitComment();
          }}
        ></i>
        {showDropdown && users && users.length > 0 && <div
          id={dropdownId}
          style={{ position: 'absolute', top: 33, left: showUser ? 40 : 0, right: 0, zIndex: 1, background: 'white', border: '1px solid rgb(193, 193, 193)', borderTop: 0, maxHeight: 130, overflow: 'auto' }}
        >{users.map((user) => {
          const { username, fullname, _id, image } = user;
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', padding: '0.125rem 0.25rem', gap: '0.5rem', cursor: 'pointer' }}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.hideDropdown = true;
                const current = `@${usernameFound}`;
                const input = document.getElementById(id);
                const atIndex = input.innerHTML.indexOf(current);
                const instances = countWordInstances(input.innerHTML, _id) + 1;
                const spanId = `${_id}_${instances}`;
                const replacement = `<span id="${spanId}" style="background: rgba(242,242,242,1);">${fullname || `@${username || `user${_id.slice(-6)}`}`}</span>`;
                const delta = replacement.length - current.length;
                const newValue = replaceSubstring(input.innerHTML, atIndex, atIndex + current.length - 1, replacement);
                input.innerHTML = newValue;
                const range = document.createRange();
                const spanElement = document.getElementById(spanId);
                range.selectNodeContents(spanElement);
                range.collapse(false);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                if (delta > 0) input.scrollLeft = input.scrollLeft + 16 * delta;
                this.forceUpdate();
              }}
            >
              <UserAvatar user={user} /> <div>{fullname || `@${username || `user${_id.slice(-6)}`}`}</div>
            </div>
          )
        })}</div>}
      </AddEditComment>
    );
  }
  getComments = (app, account, comment) => {
    const id = comment && comment._id || app._id;
    if (!this.state.commentCaches[id] && !id.startsWith('temp_')) {
      this.state.commentCaches[id] = getAppComments(app._id, comment && comment._id || null);
      this.state.commentCaches[id].onStateUpdate(this);
    }
    const commentCache = this.state.commentCaches[id];
    let comments = commentCache && commentCache.currentPage || [];
    let commentUsers;
    let dontLoadComments = false;
    if (comments && commentCache && commentCache.allFound && this.state.previousComments[id] && this.state.previousComments[id][0] && this.state.previousComments[id][0]._id.startsWith('temp_')) {
      const previousComments = this.state.previousComments[id].map(({ _id }) => _id).filter((_id) => !_id.startsWith('temp_')).join('');
      const newComments = comments.map(({ _id }) => _id).join('');
      dontLoadComments = previousComments === newComments;
    }
    if (comments && commentCache && commentCache.allFound && !dontLoadComments) {
      const commentUserIds = new Set();
      comments.forEach(({ owner, text }) => {
        commentUserIds.add(owner);
        const regex = /@([a-fA-F0-9]{24})/g;
        const matches = text.match(regex) || [];
        matches.forEach((m) => {
          commentUserIds.add(m.slice(1));
        });
      });
      commentUsers = comments && UserCache.getUsersByIds(Array.from(commentUserIds));
      if (commentUsers && commentUsers.length > 0) {
        this.state.previousComments[id] = comments;
        this.state.previousCommentUsers[id] = commentUsers;
      }
    }
    if (!comments || !commentUsers) {
      comments = this.state.previousComments[id];
      commentUsers = this.state.previousCommentUsers[id];
    }
    if (!comments || comments.length === 0 || !commentUsers) {
      return this.addEditComment(account, app, comment);
    }
    return <>
      {comment && <div
        style={{ display: 'flex', margin: '0.5rem 0', alignItems: 'center', cursor: 'pointer', fontSize: Constants.SmallFontSize }}
        onClick={() => { if (comment) {
          this.state.showReplies[comment._id] = !this.state.showReplies[comment._id];
          this.forceUpdate();
        } }}
      >
        <i
          className={comment && this.state.showReplies[comment._id] ? 'fas fa-caret-up' : 'fas fa-caret-down'}
          style={{ marginRight: '0.5rem' }}
        ></i>{formatReplyCount(comments.length)}
      </div>}
      {this.addEditComment(account, app, comment)}
      {!(comment && !this.state.showReplies[comment._id]) && comments && (<>
        {comments.map((c) => this.getComment(c, commentUsers, account, (app)))}
        {commentCache && commentCache.showMore && <ShowMoreButton onClick={() => commentCache.getMore()}>Show More</ShowMoreButton>}
      </>)}
    </>
  }
  likeComment = (like, comment) => {
    if (!UserAccount.isLoggedIn) return Navigation.push(Navigation.login);
    if (like) {
      setTimeout(() => {
        LogicBaseCommentLikeCache.__deleteQueryFromCache({ fr: UserAccount.account._id, c: like.c });
        LogicBaseCommentLikeCache.delete(like, null, true);
      });
      comment.likes -= 1;
      this.forceUpdate();
    } else {
      setTimeout(() => {
        LogicBaseCommentLikeCache.__deleteQueryFromCache({ fr: UserAccount.account._id, c: comment._id });
        LogicBaseCommentLikeCache.create(LogicBaseCommentLikeCache.createObject({ fr: UserAccount.account._id, c: comment._id }), true);
      });
      if (comment.likes) comment.likes += 1;
      else comment.likes = 1;
      this.forceUpdate();
    }
  }
  likeApp = (like, app) => {
    if (!UserAccount.isLoggedIn) return Navigation.push(Navigation.login);
    if (like) {
      setTimeout(() => {
        LogicBaseAppLikeCache.__deleteQueryFromCache({ fr: UserAccount.account._id, a: app._id });
        LogicBaseAppLikeCache.delete(like, null, true);
      });
      app.likes -= 1;
      this.forceUpdate();
    } else {
      setTimeout(() => {
        LogicBaseAppLikeCache.__deleteQueryFromCache({ fr: UserAccount.account._id, a: app._id });
        LogicBaseAppLikeCache.create(LogicBaseAppLikeCache.createObject({ fr: UserAccount.account._id, a: app._id }), true);
      });
      if (app.likes) app.likes += 1;
      else app.likes = 1;
      this.forceUpdate();
    }
  }
  getComment = (comment, commentUsers, account, app) => {
    const { text, owner, createdTime, _id, likes } = comment;
    const user = commentUsers.find(({ _id }) => {
      return _id === owner
    });
    const likesFound = _id && !_id.startsWith('temp_') && UserAccount.isLoggedIn && (LogicBaseCommentLikeCache.query({ fr: UserAccount.account._id, c: _id }) || []);
    if (!user) return null;
    if (UserAccount.isLoggedIn && _id && !_id.startsWith('temp_') && !likesFound) return null;
    const like = likesFound && likesFound[0];
    const formattedTextElements = getFormattedTextElements(text);
    return (
      <div key={_id} style={{ margin: '1rem 0' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem' }}>
          <UserProfileHeader
            user={{
              _id: user._id,
              image: user.image,
              fullname: user.fullname,
            }}
            small
            hideFollow
            hideProfile
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem', flex: 1 }}>
            <div style={{ fontSize: Constants.SmallFontSize, fontWeight: 500 }}>
              {user.fullname || `@${user.username || `user${user._id.slice(-6)}`}`}
            </div>
            {this.state.editReply[comment._id] && this.addEditComment(account, app, null, comment) || <div style={{ wordBreak: 'break-word' }}>
              {formattedTextElements}
            </div>}
            <CommentButtons>
              <div style={{ fontSize: Constants.SmallFontSize, padding: '0.5rem 0.5rem 0.5rem 0' }}>{timeAgo(new Date(createdTime))}</div>
              <div
                  onClick={() => { this.likeComment(like, comment) }}
                  style={{ fontSize: Constants.SmallFontSize, cursor: 'pointer', padding: '0.5rem' }}
                ><i className={like ? "fas fa-thumbs-up" : "far fa-thumbs-up"}></i>&nbsp;&nbsp;{shortenLikesCount(likes || 0)} {likes === 1 ? 'like' : 'likes'}</div>
              <div style={{ fontSize: Constants.SmallFontSize, cursor: 'pointer', padding: '0.5rem' }}
                onClick={() => { if (comment) {
                  this.state.showReply[comment._id] = !this.state.showReply[comment._id];
                  if (this.state.showReply[comment._id]) {
                    this.state.currentReply = `comment_${comment._id}_reply`;
                    this.state.showReplies[comment._id] = true;
                  }
                  this.forceUpdate();
                } }}
              >{this.state.showReply[comment._id] ? 'close reply' : 'reply'}</div>
              {owner === account._id && <>
                <div
                  onClick={() => { if (comment) {
                    this.state.editReply[comment._id] = !this.state.editReply[comment._id];
                    if (this.state.editReply[comment._id]) {
                      this.state.currentReply = `comment_${comment._id}`;
                    }
                    this.forceUpdate();
                  } }}
                  style={{ fontSize: Constants.SmallFontSize, cursor: 'pointer', padding: '0.5rem' }}
                >{this.state.editReply[comment._id] ? 'close edit': 'edit'}</div>
                <div
                  style={{ fontSize: Constants.SmallFontSize, cursor: 'pointer', padding: '0.5rem' }}
                  onClick={() => this.setState({ deleteComment: comment })}
                >delete</div>
              </>}
            </CommentButtons>
          </div>
        </div>
        <div style={{ marginLeft: 40 }}>
          {this.getComments(app, account, comment)}
        </div>
      </div>
    );
  }
  goFullscreen = (fullscreen) => {
    this.setState({ fullscreen });
    if (Constants.isIphone) window.logictry.setFullscreen(fullscreen);
  }
  onPagination = (cacheUsed) => {
    if (cacheUsed && cacheUsed.showMore && cacheUsed.allFound && cacheUsed.initialized) {
      cacheUsed.getMore();
      this.forceUpdate();
    }
  }
  render() {
    const { isLoggedIn, account } = UserAccount;
    const { fullscreen, showShareLinks, deleteComment, appname } = this.state;
    const { collection } = Navigation.currentSearch;
    const appid = Navigation.currentLocation[1] === 'apps' && Navigation.currentLocation[2];
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const notFound = <FullScreenVerticalCenterContent><h1>Page Not Found</h1></FullScreenVerticalCenterContent>;

    let owner;
    let publicApp;
    let app;
    let relatedApps;
    let cacheUsed;
    if (appid) {
      app = TreeCache.getTree(appid);
      if (!app) return loading;
      if (app.error) return notFound;
      owner = app.owner;
      if (!this.appsCache) {
        const text = app.title.text && app.description.text && `${app.title.text} ${app.description.text}`
          || app.title.text || app.description.text;
        this.appsCache = getLogicBaseAppsSearch(text);
        this.appsCache.onStateUpdate(this);
      }
      relatedApps = this.appsCache.currentPage.filter((a) => a._id !== app._id);
      cacheUsed = this.appsCache;
    } else {
      const publicApps = LogicBaseAppCache.query({ u: appname.toLowerCase() });
      const collections = collection && LogicBaseCollectionCache.query({ u: collection.toLowerCase() });
      if (collection && !collections) return loading;
      if (collection && collections.length === 0) return notFound;
      const collectionApps = collection && LogicBaseAppCache.getByIds(collections[0].apps);
      if (!publicApps) return loading;
      publicApp = publicApps[0];
      if (!publicApp || !publicApp._id) return notFound;
      if (collection) {
        relatedApps = collectionApps;
      } else {
        if (!this.appsCache) {
          const text = publicApp.title && publicApp.description && `${publicApp.title} ${publicApp.description}`
            || publicApp.title || publicApp.description || appname.split('-').join(' ');
          this.appsCache = getLogicBaseAppsSearch(text);
          this.appsCache.onStateUpdate(this);
        }
        relatedApps = this.appsCache && this.appsCache.currentPage.filter((a) => a._id !== publicApp._id);;
        cacheUsed = this.appsCache;
      }
      owner = publicApp.owner;
    }
    if (this.topLikesCache && this.appsCache && this.appsCache.allFound && relatedApps.length === 0) {
      relatedApps = this.topLikesCache.currentPage;
      cacheUsed = this.topLikesCache;
    }
    const publicUser = LogicBaseUserCache.get(owner);
    if (!publicUser) return loading;
    const users = UserCache.getUsersByIds([owner]);
    if (!users) return loading;
    const user = users[0];
    if (!user || !user._id) return notFound;
    const { mobile } = WindowSize;
    const onUpvote = () => {
      const relationship = (isLoggedIn && LogicBaseAppSaveCache.query({ fr: account._id, a: publicApp && publicApp._id || app && app._id }) || [])[0];
      if (relationship) LogicBaseAppSaveCache.delete(relationship);
      else LogicBaseAppSaveCache.create(LogicBaseAppSaveCache.createObject({ fr: account._id, a: publicApp && publicApp._id || app && app._id }));
    }
    const youFlagged = (isLoggedIn) ? (LogicBaseAppReportCache.query({ o: UserAccount.account._id, a: (publicApp || app)._id }) || this.youFlagged) : [];
    this.youFlagged =  youFlagged;
    const relationship = (isLoggedIn && LogicBaseAppSaveCache.query({ fr: account._id, a: publicApp && publicApp._id || app && app._id }) || [])[0];
    if (fullscreen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
    const like = isLoggedIn && (LogicBaseAppLikeCache.query({ fr: UserAccount.account._id, a: (publicApp || app)._id }) || [])[0];
    const appDescription = publicApp && publicApp.description || app && app.description.text;
    return (
      <Wrapper key={publicApp && publicApp._id || app._id}>
        <Scrollable
          vertical={!mobile}
          paginationHeight={400}
          onPagination={() => this.onPagination(cacheUsed)}
          style={mobile ? { position: 'relative', margin: 0 } : { position: 'absolute', inset: 0 }}
        >
          <ContentWrapper>
            <div>
              <Scrollable
                vertical
                style={window.innerWidth < 1329 ? { position: 'relative', margin: 0 } : { position: 'absolute', inset: 0 }}
              >
                <AppWrapper
                  style={fullscreen && { position: 'fixed', inset: 0, zIndex: 1, height: window.innerHeight, border: 'unset', borderRadius: 0 } || { height: mobile && `calc(${initialInnerHeight}px - ${Constants.isIphone && '200px' || '150px'})` || window.innerHeight < 900 && `calc(${window.innerHeight}px - 200px)` || `calc(${window.innerHeight}px - 310px)` }}
                >
                  <TreeAnswerPage showAppId={publicApp && publicApp._id || app._id} fullscreen />
                </AppWrapper>
                {(!fullscreen || !mobile) && <ProfileAndShare>
                  <div>
                    <div>
                      <ShareIcons style={{ fontSize: Constants.NormalFontSize, width: 40, height: 40, padding: 13 }} onClick={() => this.goFullscreen(true)}><i className="fas fa-expand"></i></ShareIcons>
                      {publicApp && <ShareIcons onClick={() => this.likeApp(like, (publicApp))}><i className={like ? "fas fa-thumbs-up" : "far fa-thumbs-up"}></i>&nbsp;&nbsp;{shortenLikesCount(publicApp.likes || 0)} {publicApp.likes === 1 ? 'like' : 'likes'}</ShareIcons>}
                      {publicApp && <ShareIcons onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ menuOpen: false }); isLoggedIn ? onUpvote() : Navigation.push(Navigation.login); }}>
                        <i className={relationship ? "fas fa-star" : "far fa-star"}></i>&nbsp;&nbsp;{relationship ? 'Unpin' : 'Pin'}
                      </ShareIcons>}
                      <ShareIcons onClick={() => this.setState({ showShareLinks: true })}><i className="fas fa-share"></i>&nbsp;&nbsp;Share</ShareIcons>
                      <ShareIcons style={{ padding: 0 }}>
                        <LogicBaseAppSettings app={publicApp || app} />
                      </ShareIcons>
                    </div>
                  </div>
                  <UserProfile>
                    <div>
                      <UserProfileHeader
                        user={{
                          _id: user._id,
                          image: user.image,
                          description: user.description,
                          username: user.username,
                          fullname: user.fullname,
                          apps: publicUser && publicUser.apps || [],
                          followers: publicUser && publicUser.followers || [],
                          followees: publicUser && publicUser.followees || [],
                        }}
                        small
                      />
                    </div>
                  </UserProfile>
                </ProfileAndShare>}
                {(youFlagged && youFlagged[0]) && <div>
                  <div style={{ background: 'red', color: 'white', padding: '0.25rem 0.5rem', display: 'inline-block' }}>Flagged for review</div>
                </div>}
                {(!fullscreen || !mobile) && <CommentsSection>
                  <h1 style={{ fontSize: Constants.SemiLargeFontSize }}>{publicApp && publicApp.title || app && app.title.text}</h1>
                  {appDescription && <p>{appDescription}</p>}
                  <Comments>
                    {this.getComments((app || publicApp), account)}
                  </Comments>
                  <div></div>
                </CommentsSection>}
              </Scrollable>
            </div>
            {(!fullscreen || !mobile) && <AppsWrapper>
              <Scrollable
                vertical
                paginationHeight={400}
                onPagination={() => this.onPagination(cacheUsed)}
                style={window.innerWidth < 1329 ? { position: 'relative', margin: 0 } : { position: 'absolute', inset: 0 }}
                rememberScrollPosition={`logicbaseapppage_applist`}
              >
                <AppListContent>
                  <LogicBaseAppList showUser apps={(relatedApps || [])} listView small />
                </AppListContent>
                <div style={{ margin: '0 auto 2rem', display: 'flex', justifyContent: 'center' }}>
                  {relatedApps && relatedApps.length > 0 && cacheUsed && !cacheUsed.allFound && <LoadingIndicator />}
                </div>
              </Scrollable>
            </AppsWrapper>}
            {fullscreen && <ShareIcons style={{ fontSize: Constants.NormalFontSize, position: 'fixed', top: mobile ? 'unset' : 16, right: mobile ? 16 : 16, bottom: mobile ? 16 : 'unset', width: 36, height: 36, padding: 12, boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 4px 0px', background: 'white', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.goFullscreen(false)}><i className="fas fa-compress"></i></ShareIcons>}
            {showShareLinks && <SocialSharePopup onClose={(e) => { if (e) { e.preventDefault(); e.stopPropagation(); } this.setState({ showShareLinks: false }) }} tree={publicApp || app} />}
          </ContentWrapper>
        </Scrollable>
        {deleteComment && <ConfirmDialog
          text="Are you sure you wish to delete?"
          description="This is permanent and cannot be undone."
          open onNo={() => this.setState({ deleteComment: null })} onYes={() => {
            const id = deleteComment.comment || deleteComment.app;
            if (id.startsWith('temp_')) return;
            LogicBaseCommentsCache.delete(deleteComment, true);
            this.setState({ deleteComment: null });
          }} />}
      </Wrapper>
    );
  }
}

function replaceSubstring(originalString, startIndex, endIndex, replacement) {
  var prefix = originalString.substring(0, startIndex);
  var suffix = originalString.substring(endIndex + 1);
  var replacedString = prefix + replacement + suffix;
  return replacedString;
}
function countWordInstances(inputString, targetWord) {
  const regex = new RegExp(targetWord, 'g');
  const matches = inputString.match(regex);
  const count = matches ? matches.length : 0;
  return count;
}