import React from "react";
import styled from "styled-components";
import OpenAI, { trimAndRemoveFORMATTER } from '../../services/OpenAI';
import Scrollable from "../Scrollable";
import Constants from '../../submodules/logictry_config/constants';
import StatefulButton from "../StatefulButton";
import DrawerBreadcrumb from "../DrawerBreadcrumb";

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-weight: 600;
  }
  > p {
    margin: 0.5rem;
  }
  img {
    width: 60px;
  }
  ol {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  input[type="text"] {
    padding: 10px 3rem 10px 1rem;
  }
  button {
    background: #1ebd53;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    color: white;
    text-align: center;
    font-weight: 600;
  }
`;
const Suggestions = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  > div {
    border: 1px dashed;
    width: 240px;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    h3 {
      font-weight: 600;
      text-transform: capitalize;
    }
    > div {
      flex: 1;
    }
    button {
      margin: 1rem 0;
    }
  }
`;
const SearchBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 784px;
  input {
    flex: 1;
    height: 48px;
    border: 1px solid rgb(193, 193, 193);
    border-radius: 0.5rem;
  }
`;

// let suggestions = [
//   {
//       "t": "quiz",
//       "d": "Construction Safety Knowledge Quiz: Test your knowledge on construction safety protocols and OSHA regulations."
//   },
//   {
//       "t": "assessment",
//       "d": "Project Management Skills Assessment: Evaluate your proficiency in managing construction projects, including time management, resource allocation, and communication."
//   },
//   {
//       "t": "decision tree",
//       "d": "Change Order Decision Tree: Guide users through a series of questions to determine the best course of action when faced with potential project changes."
//   },
//   {
//       "t": "checklist",
//       "d": "Site Inspection Checklist: Interactive checklist for site managers to ensure all safety and compliance measures are met before starting work."
//   },
//   {
//       "t": "buying guide",
//       "d": "Construction Software Buying Guide: Help potential customers choose the right Procore product based on their company size, project complexity, and specific needs."
//   },
//   {
//       "t": "learning path",
//       "d": "Procore Certification Path: Structured interactive learning modules that lead users towards becoming Procore Certified with different levels of expertise."
//   }
// ]

class GoInteractive extends React.PureComponent {
  state = {
    busy: false,
    letters: 0,
    response: '',
  }
  componentDidMount() {
    OpenAI.onStateUpdate(this.openAIUpdate);
  }
  componentWillUnmount() {
    OpenAI.offStateUpdate(this.openAIUpdate);
    clearInterval(this.textUpdateInterval);
  }
  openAIUpdate = () => {
    if (!OpenAI.openAIState) return;
    const { generating } = this.state;
    const { description, response, complete } = OpenAI.openAIState;
    if (generating) {
      this.openAIText = `Generating ${description}\n\n${trimAndRemoveFORMATTER(response)}`;
      this.setState({ busy: !complete });
    } else if (OpenAI.suggestions) {
      this.openAIText = `Generating your website suggestions!\n\n${OpenAI.suggestions.map(({ d }) => (`${d}`)).join('\n\n')}`;
      this.setState({ busy: !complete });
    }
    if (!complete) {
      if (!this.textUpdateInterval) {
        this.textUpdateInterval = setInterval(this.textUpdate, 40);
      }
    } else {
      clearInterval(this.textUpdate);
      this.textUpdateInterval = null;
    }
  }
  getSuggestions = async () => {
    const website = document.getElementById('GOINTERACTIVE__WEBSITE').value;
    this.state.busy = true;
    this.state.letters = 0;
    this.state.response = '';
    this.setState({ busy: true, letters: 0, response: '' });
    OpenAI.getSuggestions(website);
  }
  textUpdate = () => {
    if (!this.openAIText) return;
    let { letters } = this.state;
    if (this.openAIText.length > letters) letters += 1;
    this.setState({ response: this.openAIText.slice(0, letters), letters });
  }
  generateContent = async (type, description) => {
    this.state.generating = description;
    this.state.letters = 0;
    this.state.busy = true;
    this.state.response = '';
    OpenAI.createContent({ type, description });
  }
  render() {
    const { mobile } = Constants;
    const { busy, response } = this.state;
    const { website, suggestions } = OpenAI;
    return (
      <Scrollable
        key={"Go Interactive"}
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0 }}
        rememberScrollPosition={`gointeractive`}
      >
        <Wrapper>
          <DrawerBreadcrumb />
          <SearchBar>
            <input id="GOINTERACTIVE__WEBSITE" placeholder="Your Website (URL)" type="text" defaultValue={website}></input>
            <StatefulButton
              onClick={this.getSuggestions}
              text="Go!"
              state={busy ? 'busy' : null}
            />
          </SearchBar>
          {busy && <div style={{ width: '100%', maxWidth: 784, margin: '2rem 0' }}>
            <div
              style={{ whiteSpace: 'pre-wrap' }}
            >{response}</div>
          </div> || suggestions && <Suggestions>  
            {suggestions.map(({ t, d }) => (
              <div key={d}>
                <h3>{t}</h3>
                <div>{d}</div>
                {t === 'learning path' && <button>Coming Soon!</button> || <button onClick={() => this.generateContent(t, d)}>Generate</button>}
              </div>
            ))}
          </Suggestions>}
        </Wrapper>
      </Scrollable>
    )
  }
};
export default GoInteractive;
