/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Constants from '../../submodules/logictry_config/constants';
import Company from '../../services/Company';
import Popup from '../Popup/index';
import Button from '../Button/index';
import ClickableIcon from '../ClickableIcon/index';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 90vw;
  > div:nth-child(2) {
    border-top: 1px solid ${Constants.DarkGray};
    padding: 20px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const WebsiteInput = styled.div`
  > div:first-child {
    display: flex;
    > div {
      line-height: 40px;
    }
    input:nth-child(1) {
      width: 100px;
    }
    input:nth-child(2) {
      width: 300px;
    }
    * {
      margin: 10px;
    }
  }
  > div:last-child {
    margin-left: 20px;
  }
`;

export default class EditCompanyHomePopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
  }
  componentDidMount() {
    Company.onStateUpdate(this);
  }
  componentWillUnmount() {
    Company.offStateUpdate(this);
  }
  changeProperty = (website, value) => {
    website._id = value;
  }
  close = () => {
    const { company } = Company;
    const { onClose } = this.props;
    Company.revertObject(company);
    onClose();
  }
  saveAndClose = () => {
    const { company } = Company;
    const { onClose } = this.props;
    Company.update(company);
    onClose();
  }
  createWebsiteInputs = (level, parentWebsite, hideDelete) => {
    const website = parentWebsite[level];
    return (
      <WebsiteInput key={level || 'new'}>
        <div key={level || 'new'}>
          <input disabled={hideDelete} tabIndex={0} onBlur={(e) => this.changePath(parentWebsite, level, e.target.value)} placeholder="Path" defaultValue={level || ''} />
          <input tabIndex={0} onChange={(e) => this.changeProperty(website, e.target.value)} placeholder="App ID" defaultValue={website._id || ''} />
          <ClickableIcon onClick={() => { this.changeProperty(website, ''); this.forceUpdate(); }} icon="fas fa-trash" />
        </div>
      </WebsiteInput>
    )
  }
  render() {
    const { company } = Company;
    company.home = company.home || {};
    company.publicHome = company.publicHome || {};
    return (
      <Popup
        onClose={this.close}
        allowBackgroundClose
      >
        <Wrapper>
          <h1>Edit Company Home</h1>
          <div>
            <div key={JSON.stringify(company.home)}>
              {this.createWebsiteInputs('home', { home: company.home }, true)}
            </div>
            <div key={JSON.stringify(company.publicHome)}>
              {this.createWebsiteInputs('publicHome', { publicHome: company.publicHome }, true)}
            </div>
          </div>
          <ButtonsWrapper>
            <Button onClick={this.close} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.saveAndClose} color="primary" autoFocus>
              Save
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </Popup>
    );
  }
}
