import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import WindowSize from '../../services/WindowSize';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import LogicBaseCommentsCache from '../../services/cache/LogicBaseCommentsCache';
import LogicBaseCommentLikeCache from '../../services/cache/LogicBaseCommentLikeCache';
import LogicBaseAppLikeCache from '../../services/cache/LogicBaseAppLikeCache';
import LogicBaseAppSaveCache from '../../services/cache/LogicBaseAppSaveCache';
import LogicBaseCollectionSubscriptionCache from '../../services/cache/LogicBaseCollectionSubscriptionCache';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import UserCache from '../../services/cache/UserCache';
import LogicBaseRelationshipCache from '../../services/cache/LogicBaseRelationshipCache';
import LogicBaseNotificationCache from '../../services/cache/LogicBaseNotificationCache';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import { getNotifications } from '../../services/Pagination';
import timeAgo from '../../utils/timeAgo';
import Scrollable from '../../components/Scrollable';
import UserProfileHeader from '../../components/UserProfileHeader';
import getFormattedTextElements from '../../utils/getFormattedTextElements';

const NotificationWrapper = styled.div`
  max-width: 48rem;
  margin: auto;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 2rem 0;
    text-align: center;
  }
`;
const NotificationSection = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  > div {
    display: flex;
    width: 100%;
    > div:nth-child(2) {
      flex: 1;
      > div:first-child {
        cursor: pointer;
      }
    }
    > div:last-child {
      i {
        padding: 1rem;
        cursor: pointer;
      }
    }
  }
`;


export default class LogicBaseNotificationPage extends React.PureComponent {
  constructor() {
    super();
    const { account } = UserAccount;
    this.notifications = getNotifications(account._id);
  }
  state = {
    showNotifications: [],
    newNotifications: {},
  }
  componentDidMount() {
    LogicBaseUserCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    this.notifications.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    LogicBaseAppCache.onStateUpdate(this);
    LogicBaseRelationshipCache.onStateUpdate(this);
    LogicBaseCommentsCache.onStateUpdate(this);
    LogicBaseCollectionCache.onStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.onStateUpdate(this);
    LogicBaseNotificationCache.onStateUpdate(this);
    LogicBaseCommentLikeCache.onStateUpdate(this);
    LogicBaseAppLikeCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseUserCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    this.notifications.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    LogicBaseAppCache.offStateUpdate(this);
    LogicBaseRelationshipCache.offStateUpdate(this);
    LogicBaseCommentsCache.offStateUpdate(this);
    LogicBaseCollectionCache.offStateUpdate(this);
    LogicBaseCollectionSubscriptionCache.offStateUpdate(this);
    LogicBaseNotificationCache.offStateUpdate(this);
    LogicBaseCommentLikeCache.offStateUpdate(this);
    LogicBaseAppLikeCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  approveDeny = (followRequest, approve) => {
    LogicBaseRelationshipCache.__deleteQueryFromCache({ fe: followRequest.followee, r: true, limit: 1 });
    if (approve) {
      followRequest.requested = false
      LogicBaseRelationshipCache.__deleteQueryFromCache({ fr: followRequest.follower, fe: followRequest.followee, r: true });
      LogicBaseRelationshipCache.update(followRequest);
    } else {
      LogicBaseRelationshipCache.__deleteQueryFromCache({ fr: followRequest.follower, fe: followRequest.followee, r: true });
      LogicBaseRelationshipCache.delete(followRequest);
    }
  }
  clearNotification = (notification) => {
    notification.read = true;
    LogicBaseNotificationCache.update(notification);
  }
  onPagination = () => {
    if (this.notifications.showMore && this.notifications.allFound && this.notifications.initialized) {
      this.notifications.getMore();
    }
  }
  getUserAction = (user, url, text, createdTime, image) => (
    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem' }}>
      {user && <UserProfileHeader
        user={{
          _id: user._id,
          image: user.image,
          description: user.description,
          username: user.username,
          fullname: user.fullname,
        }}
        small
        hideFollow
        hideProfile
      />}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0.25rem' }}>
        <div onClick={() => Navigation.push(url)}>{user.fullname && `${user.fullname} ` || `@${user.username || `user${user._id.slice(-6)}`} ` || ''}{text}</div>
        <div style={{ fontSize: Constants.SmallFontSize }}>{timeAgo(new Date(createdTime))}</div>
      </div>
    </div>
  )
  render() {
    const { mobile } = WindowSize;
    const { account } = UserAccount;
    let notifications = this.notifications.currentPage;
    if (notifications.length > 0) this.lastNotifications = notifications;
    else notifications = this.lastNotifications;
    if (!this.clearTimeout) {
      this.clearTimeout = setTimeout(() => {
        this.clearTimeout = null;
        LogicBaseNotificationCache.__deleteQueryFromCache({ o: account._id, r: false, limit: 1 });
      }, 250);
    }

    const followRequest = (LogicBaseRelationshipCache.query({ 'fe': account._id, 'r': true, limit: 1 }) || [])[0];
    const collectionSubscriptionIds = notifications && notifications.map(({ objectId, type }) => type === 'NewCollectionSubscriber' && objectId).filter((v) => v);
    const getSubscriptions = collectionSubscriptionIds && collectionSubscriptionIds.length > 0;
    const collectionSubscriptions = getSubscriptions && LogicBaseCollectionSubscriptionCache.getByIds(Array.from(new Set(collectionSubscriptionIds)));

    const commentLikeIds = notifications && notifications.map(({ objectId, type }) => type === 'NewCommentLike' && objectId).filter((v) => v);
    const getCommentLikes = commentLikeIds && commentLikeIds.length > 0;
    const commentLikes = getCommentLikes && LogicBaseCommentLikeCache.getByIds(Array.from(new Set(commentLikeIds)));
    const appLikeIds = notifications && notifications.map(({ objectId, type }) => type === 'NewAppLike' && objectId).filter((v) => v);
    const getAppLikes = appLikeIds && appLikeIds.length > 0;
    const appLikes = getAppLikes && LogicBaseAppLikeCache.getByIds(Array.from(new Set(appLikeIds)));
    const appPinIds = notifications && notifications.map(({ objectId, type }) => type === 'NewAppPin' && objectId).filter((v) => v);
    const getAppPins = appPinIds && appPinIds.length > 0;
    const appPins = getAppPins && LogicBaseAppSaveCache.getByIds(Array.from(new Set(appPinIds)));

    const commentIds = [
      ...(notifications || []).map(({ objectId, type }) => type === 'NewComment' && objectId).filter((v) => v),
      ...(commentLikes || []).map(({ comment }) => comment).filter((v) => v),
    ];
    const getComments = commentIds && commentIds.length > 0;
    const comments = getComments && (!getCommentLikes || commentLikes) && LogicBaseCommentsCache.getByIds(Array.from(new Set(commentIds)));
    const commentUserIds = new Set();
    comments && comments.forEach(({ text }) => {
      const regex = /@([a-fA-F0-9]{24})/g;
      const matches = text && text.match(regex) || [];
      matches.forEach((m) => { commentUserIds.add(m.slice(1)) })
    });

    const collectionIds = Array.from(new Set([
      ...(collectionSubscriptions || []).map(({ collection }) => collection).filter((v) => v),
      ...(notifications || []).map(({ objectId, type }) => (type === 'NewCollectionApp' || type === 'NewCollectionEditor') && objectId).filter((v) => v)
    ]));
    const getCollections = collectionIds && collectionIds.length > 0;
    const collections = getCollections && LogicBaseCollectionCache.getByIds(collectionIds);
    const apps = notifications && (!getComments || comments) && (!getAppLikes || appLikes) && (!getAppPins || appPins) && LogicBaseAppCache.getByIds(Array.from(new Set([
      ...(comments || []).map(({ app }) => app).filter((v) => v),
      ...(appLikes || []).map(({ app }) => app).filter((v) => v),
      ...(appPins || []).map(({ app }) => app).filter((v) => v)
    ])));

    // Get Users
    const userIds = notifications
      &&(!getCollections || collections)
      && (!getComments || comments)
      && (!getSubscriptions || collectionSubscriptions)
      && (!getCommentLikes || commentLikes)
      && (!getAppLikes || appLikes)
      && (!getAppPins || appPins)
      && [
        ...notifications.map(({ type, objectId }) => (['NewFollowRequest', 'NewFollower', 'FollowApproved'].includes(type)) && objectId),
        ...(comments || []).map(({ owner }) => owner),
        ...(collections || []).map(({ owner }) => owner),
        ...(collectionSubscriptions || []).map(({ follower }) => follower),
        ...(commentLikes || []).map(({ follower }) => follower),
        ...(appLikes || []).map(({ follower }) => follower),
        ...(appPins || []).map(({ follower }) => follower),
        ...(apps || []).map(({ owner }) => owner),
        ...(Array.from(commentUserIds))
      ].filter((v) => v);
    const getUsers = userIds && userIds.length > 0;
    const users = getUsers && UserCache.getUsersByIds(Array.from(new Set(userIds)));

    if (!(!notifications
      || (getComments && (!comments || !apps))
      || (getUsers && !users)
      || (getSubscriptions && !collectionSubscriptions)
      || (getCollections && !collections)
      || (getAppLikes && !appLikes)
      || (getAppPins && !appPins)
      || (getCommentLikes && !commentLikes)
    )) {
      const newNotifications = [];
      notifications.forEach((notification) => {
        const { _id, createdTime, type, objectId, read } = notification;
        if (!read) {
          this.state.newNotifications[_id] = true;
        }
        const commentLike = commentLikes && commentLikes.find(({ _id }) => _id === objectId);
        const comment = comments && comments.find(({ _id }) => _id === (commentLike && commentLike.comment || objectId));
        const appLike = appLikes && appLikes.find(({ _id }) => _id === objectId);
        const appPin = appPins && appPins.find(({ _id }) => _id === objectId);
        const collectionSubscription = collectionSubscriptions && collectionSubscriptions.find(({ _id }) => _id === objectId);
        const commentUser = (commentLike || comment || appLike || appPin) && users && users.find(({ _id }) => _id === (commentLike && commentLike.follower || appLike && appLike.follower || appPin && appPin.follower || comment && comment.owner));
        const app = (comment || appLike || appPin) && apps && apps.find(({ _id }) => _id === (comment || appLike || appPin).app);

        const subscriptionUser = collectionSubscription && users && users.find(({ _id }) => _id === collectionSubscription.follower);
        const collection = collectionSubscription && collections && collections.find(({ _id }) => _id === collectionSubscription.collection) || collections && collections.find(({ _id }) => _id === objectId);
        const collectionOwner = collection && users && users.find(({ _id }) => _id === collection.owner);
        const user = users && users.find(({ _id }) => _id === objectId);
        const followRequests = (type === 'NewFollowRequest') && LogicBaseRelationshipCache.query({ 'fr': user._id, 'fe': account._id });
        const followRequest = followRequests && followRequests[0];
        this.clearNotification(notification);
        if (type === 'NewFollowRequest' && !followRequest) {
          // if (followRequests && followRequests.length === 0) this.clearNotification(notification);
          return;
        }
        if (type === 'NewComment' && !app) {
          // this.clearNotification(notification);
          return;
        }
        if (type === 'NewCollectionSubscriber' && (!subscriptionUser || !collection)) {
          // this.clearNotification(notification);
          return;
        }
        if ((type === 'NewCollectionApp' || type === 'NewCollectionEditor') && (!collection)) {
          // this.clearNotification(notification);
          return;
        }
        if ((type === 'NewCommentLike' || type === 'NewAppLike' || type === 'NewAppPin') && (!app)) {
          // this.clearNotification(notification);
          return;
        }

        let commentText = null;
        if (comment) {
          if (comment.text && account && account.username && comment.text.includes(`@${account._id}`)) commentText = <>{'mentioned you in a comment: '}{getFormattedTextElements(comment.text)}</>;
          else if (comment.comment) commentText = <>{'replied to your comment: '}{getFormattedTextElements(comment.text)}</>;
          else commentText = <>{'commented on your app: '}{getFormattedTextElements(comment.text)}</>
        }
        newNotifications.push({ _id, type, followRequest, notification, app, subscriptionUser, collection, commentUser, createdTime, user, collectionOwner, comment, commentText });
      });
      this.state.showNotifications = newNotifications;
    }
    return (
      <Scrollable
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPagination}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: mobile ? '1rem 1rem 2rem' : '1rem 2rem 2rem' }}
        rememberScrollPosition={`logicbasenotifications`}
      >
        <NotificationWrapper>
          <h1>Notifications</h1>
          <NotificationSection>
            {(!account.username || !account.fullname || (!account.image || !account.image.link)) && <div style={{ margin: '8px 12px', display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: 'pointer' }} onClick={() => Navigation.push('/account/settings')}>
              <div style={{ fontWeight: 600 }}>Your contact information is incomplete</div>
              <i className="fas fa-angle-right"></i>
            </div>}
            {followRequest && <div style={{ margin: '8px 12px', display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: 'pointer' }} onClick={() => Navigation.push('/account/follow-requests')}>
              <div>See follow requests</div>
              <i className="fas fa-angle-right"></i>
            </div>}
            {this.state.showNotifications.map(({ _id, type, followRequest, collectionOwner, app, subscriptionUser, collection, commentUser, createdTime, user, comment, commentText }) => {
              return (
                <div key={_id}>
                  {<div style={{ width: 4, height: 4, borderRadius: '50%', background: this.state.newNotifications[_id] ? 'red' : 'unset', marginTop: 14, marginRight: 8 }}></div>}
                  <div>
                    {type === 'NewComment' && this.getUserAction(commentUser, `/${app.url}`, commentText, createdTime)
                      || type === 'NewFollower' && this.getUserAction(user, Navigation.userProfile(user), 'started following you!', createdTime)
                      || type === 'NewFollowRequest' && <div style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem' }}>
                        {user && <UserProfileHeader
                          user={{
                            _id: user._id,
                            image: user.image,
                            description: user.description,
                            username: user.username,
                            fullname: user.fullname,
                          }}
                          small
                          hideFollow
                          hideProfile
                        />}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0.25rem', marginRight: '0.5rem', flex: 1 }}>
                          <div onClick={() => Navigation.push(Navigation.userProfile(user))}>{user ? (user.fullname && `${user.fullname} ` || `@${user.username || `user${user._id.slice(-6)}`} `) : ''} {followRequest.requested ? 'requested to follow you!' : 'started following you!'}</div>
                          <div style={{ fontSize: Constants.SmallFontSize }}>{timeAgo(new Date(createdTime))}</div>
                        </div>
                        {followRequest.requested && (
                          <>
                            <button style={{ cursor: 'pointer', color: 'white', background: Constants.PrimaryColor, padding: '0.25rem 0.5rem', borderRadius: '0.5rem' }} onClick={() => this.approveDeny(followRequest, true)}>Approve</button>
                            <button style={{ cursor: 'pointer', color: 'white', background: Constants.PrimaryColor, padding: '0.25rem 0.5rem', borderRadius: '0.5rem' }} onClick={() => this.approveDeny(followRequest, false)}>Reject</button>
                          </>
                        ) || (
                          <div></div>
                        )}
                      </div>
                      || type === 'FollowApproved' && this.getUserAction(user, Navigation.userProfile(user), 'accepted your follow request!', createdTime)
                      || type === 'NewCollectionSubscriber' && this.getUserAction(subscriptionUser, Navigation.userProfile(subscriptionUser), `started following your collection: "${collection.title}"`, createdTime)
                      || type === 'NewCollectionApp' && this.getUserAction(collectionOwner, `/collections/${collection.url}`, `collection: "${collection.title}" has a new app!`, createdTime, collection.image)
                      || type === 'NewCollectionEditor' && this.getUserAction(collectionOwner, `/collections/${collection.url}`, `added you as an editor to the collection: "${collection.title}"`, createdTime, collection.image)
                      || type === 'NewCommentLike' && this.getUserAction(commentUser, `/${app.url}`, <>{`liked your comment: "`}{getFormattedTextElements(comment.text)}{`"`}</>, createdTime)
                      || type === 'NewAppLike' && this.getUserAction(commentUser, `/${app.url}`, `liked your app: "${app.title}"`, createdTime)
                      || type === 'NewAppPin' && this.getUserAction(commentUser, `/${app.url}`, `pinned your app: "${app.title}"`, createdTime)
                    }
                  </div>
                  {/* <div>
                    <i className="fas fa-times" onClick={() => this.clearNotification(notification)}></i>
                  </div> */}
                </div>
              );
            }) || <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
              <div>You have no notifications</div>
            </div>}
          </NotificationSection>
        </NotificationWrapper>
      </Scrollable>
    );
  }
}
