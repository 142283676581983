import Model from './model';

export default class LogicBaseUser extends Model {
  constructor(_id, description, apps, followers, followees, verified, socialSettings, submissions, creator, pins) {
    super();
    this._id = _id;
    this.description = description;
    this.apps = apps;
    this.followers = followers;
    this.followees = followees;
    this.verified = verified;
    this.socialSettings = socialSettings;
    this.submissions = submissions;
    this.creator = creator;
    this.pins = pins;
  }
  getJson = () => {
    const { _id, description, apps, followers, followees, verified, socialSettings, submissions, creator, pins } = this;
    return {
      _id,
      d: description,
      a: apps,
      fr: followers,
      fe: followees,
      v: verified,
      ss: socialSettings,
      s: submissions,
      c: creator,
      p: pins
    }
  }
}
